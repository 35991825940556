// @vue/component
import FieldInput from '~/components/Modals/FieldInput/FieldInput'
import PrimaryButton from '~/components/AppButton/PrimaryButton'
export default {
  name: 'HeroSection',

  components: {
    FieldInput,
    PrimaryButton,
  },

  props: {},

  data() {
    return {
      searchInput: null,
      places: [],
    }
  },

  computed: {},

  watch: {},

  created() {},

  methods: {
    updateSearchInput(value) {
      this.searchInput = value
    },
    async search() {
      if (this.searchInput && this.searchInput.trim() != '') {
        let data = {
          q: this.searchInput.trim(),
        }
        this.$router.push({
          path: this.localePath('/search'),
          query: data,
        })
        await this.$store.dispatch('searchBox/getPlaces', data)
      }
    },
  },
}

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "relative w-full"
  }, [_c('div', {
    staticClass: "px-2 mx-auto max-w-5xl sm:px-6 lg:px-8"
  }, [_c('div', {
    staticClass: "relative flex flex-col py-16 m-auto rounded-lg shadow-xl bg-white-700"
  }, [_vm._m(0), _vm._v(" "), _c('AppSectionTitle', {
    staticClass: "col-span-12 mt-2 text-center justify-self-center",
    attrs: {
      "sectionTitle": 'حدد تفاصيل مناسبتك'
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "flex flex-col items-center justify-center col-span-12 lg:col-span-12"
  }, [_c('div', {
    staticClass: "min-w-full mt-4"
  }, [_c('SearchForm', {
    staticClass: "z-30",
    on: {
      "searchAgain": function ($event) {
        return _vm.$emit('searchAgain');
      }
    }
  })], 1)])], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "absolute bottom-0 left-[-100px] z-0"
  }, [_c('img', {
    staticClass: "w-40 m-4",
    attrs: {
      "src": require("assets/images/web_icons.svg"),
      "alt": ""
    }
  })]);
}]

export { render, staticRenderFns }
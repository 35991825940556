//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Tag from '~/components/Tag'

export default {
  name: 'Allplacess',
  props: {
    places: Array,
    hasShowAllLink: {
      type: Boolean,
      default: true,
    },
    hasPageination: {
      type: Boolean,
      default: false,
    },
    open_external_link: {
      type: Boolean,
      default: false,
    },

    searchAgain: {
      type: Boolean,
      default: false,
    },
  },

  components: { Tag },

  methods: {
    loadLogo(index) {
      this.$store.dispatch('searchBox/setLogoTrue', index)
    },

    //TODO: to be removed, the function was causing a bug. it should be removed later.
    // getPage(page) {
    //   try {
    //     this.$store.dispatch('home/getPlaces', { page })
    //   } catch (e) {
    //     console.log("Error: ", JSON.parse(JSON.stringify(e)))
    //     this.$swal('Error!', e.message, 'error');
    //   }
    // },
    async setActivePage(page) {
      let params = {
        page: page,
        category_id: this.activeCategory ? this.activeCategory : null,
        type_id: this.activeType ? this.activeType : null,
      }
      await this.$store.dispatch('searchBox/getPlaces', params)
      this.$store.commit('searchBox/SET_CURRENT_PAGE', page)
    },
  },
  computed: {
    filteredPlaces: function () {
      return (this.places ?? this.$store.state.searchBox.places).filter(
        (place) => place.image != null
      )
    },
    currentPage: function () {
      return this.$store.state.searchBox.currentPage
    },
    totalPages: function () {
      return this.$store.state.searchBox.totalPages
    },
    nextPage: function () {
      return this.$store.state.searchBox.nextPage
    },
    isAuth: function () {
      return this.$store.state.isLoggedIn
    },
  },
  watch: {
    searchAgain() {
      ;(this.activeCategory = null), (this.activeType = null), (this.types = [])
    },
  },
}

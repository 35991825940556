var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "z-20 flex my-2 mx-0 border-2 rounded-full  text-start border-gray  placeholder-gray-300 text-gray-500 md:my-2 bg-white-DEFAULT ",
    class: _vm.isAbsoluteIcon ? 'relative' : ''
  }, [_c('div', {
    staticClass: "w-full cursor-pointer "
  }, [_vm._t("default")], 2), _vm._v(" "), _vm.source !== -1 ? _c('div', {
    staticClass: "flex items-center justify-center py-2 text-center border-s-2 rounded-e-full border-grey-secondary bg-secondary-50",
    class: _vm.isAbsoluteIcon ? 'absolute left-0 top-0 bottom-0 w-12' : 'w-14'
  }, [_c('img', {
    staticClass: "z-10 w-4 mx-auto",
    attrs: {
      "src": _vm.source,
      "alt": ""
    }
  })]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }
import { render, staticRenderFns } from "./SearchFormContainer.vue?vue&type=template&id=248d625c&"
import script from "./SearchFormContainer.vue?vue&type=script&lang=js&"
export * from "./SearchFormContainer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppSectionTitle: require('/var/app/current/components/AppSectionTitle/AppSectionTitle.vue').default,SearchForm: require('/var/app/current/components/SearchFormContainer/SearchForm/SearchForm.vue').default})

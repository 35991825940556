var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "px-2 mx-auto max-w-5xl sm:px-6 lg:px-8 mt-14"
  }, [_c('div', {
    staticClass: "relative flex flex-col py-16 m-auto rounded-lg shadow-xl bg-white-700"
  }, [_vm._m(0), _vm._v(" "), _c('div', {
    staticClass: "flex flex-col items-center space-y-7"
  }, [_c('p', {
    staticClass: "text-2xl text-gray-600"
  }, [_vm._v("هل تخطط لعمل مناسبة في شركتك ؟")]), _vm._v(" "), _c('PrimaryButton', {
    on: {
      "click": _vm.doSomething
    }
  }, [_vm._v("مناسبات الشركات")])], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "absolute bottom-0 left-[-100px] z-0"
  }, [_c('img', {
    staticClass: "w-40 m-11",
    attrs: {
      "src": require("assets/images/businessEventsIcon.webp"),
      "alt": ""
    }
  })]);
}]

export { render, staticRenderFns }
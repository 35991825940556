var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h2', {
    staticClass: "text-2xl text-gray-600"
  }, [_vm._v(_vm._s(_vm.sectionTitle))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
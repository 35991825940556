//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  methods: {
    showPlanners() {
      this.$router.push({
        path: this.localePath('/planners'),
      })
    },
  },
}

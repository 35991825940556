//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AppSectionTitle from '../AppSectionTitle/AppSectionTitle.vue'
import SearchForm from './SearchForm/SearchForm.vue'

export default {
  data() {
    return {}
  },
  components: {
    AppSectionTitle: AppSectionTitle,
    SearchForm: SearchForm,
  },
}

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "w-full max-w-full keep-in-touch"
  }, [_c('div', {
    staticClass: "relative z-0 max-w-full bg-gray-100"
  }, [_c('div', {
    staticClass: "z-20 flex flex-col items-center justify-between px-2 py-20 mx-auto md:flex-row lg:flex-row max-w-7xl sm:px-6 lg:px-8 "
  }, [_c('div', {
    staticClass: "w-full text-center md:w-1/5 lg:w-1/4 md:text-start"
  }, [_c('h4', {
    staticClass: "text-base text-gray-400 xs:text-sm"
  }, [_vm._v("تابع اخر العروض و الخصومات")]), _vm._v(" "), _c('h2', {
    staticClass: "text-2xl font-medium text-gray-800 xs:text-xl"
  }, [_vm._v("اشترك بالنسخة البريدية")])]), _vm._v(" "), _c('div', {
    staticClass: "flex items-center w-full px-4 mt-7 md:mt-0 md:w-4/5 lg:w-3/4"
  }, [_c('input', {
    staticClass: "w-3/4 h-10 text-center text-gray-800 rounded-full outline-none bg-smoke shadow-gray-lg-40",
    attrs: {
      "type": "text",
      "placeholder": "البريد الالكتروني"
    }
  }), _vm._v(" "), _c('button', {
    staticClass: "h-10 px-10 py-2 text-white bg-gray-600 rounded-full focus:outline-none focus:ring-none ms-4 whitespace-nowrap hover:bg-gray-700"
  }, [_vm._v("اشترك الآن")])])]), _vm._v(" "), _c('img', {
    staticClass: "absolute top-0 bottom-0 right-0 h-full z--1",
    attrs: {
      "src": require("assets/images/Home/Mask Group 5.svg"),
      "alt": ""
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "relative z-30 w-full px-4 mx-auto md:mt-[-3rem] max-w-7xl"
  }, [_c('div', {
    staticClass: "flex items-center justify-center"
  }, [_c('div', {
    staticClass: "flex w-full px-5 py-6 mx-2 bg-white overscroll-auto shadow-gray-lg-40 md:w-1/2 item rounded-xl"
  }, [_c('img', {
    staticClass: "self-start me-5",
    attrs: {
      "src": require("assets/images/Home/Avatar.svg"),
      "alt": ""
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "flex flex-col"
  }, [_c('p', {
    staticClass: "mb-3 text-base font-light text-gray-400"
  }, [_vm._v("هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد\n                          النص العربى،\n                          حيث يمكنك أن تولد مثل هذا\n                          النص أو العديد من النصوص")]), _vm._v(" "), _c('div', {
    staticClass: "flex flex-row items-center"
  }, [_c('i', {
    staticClass: "text-blue-500 fa fa-user"
  }), _vm._v(" "), _c('h3', {
    staticClass: "text-base text-gray-600 ms-2"
  }, [_vm._v("يعرب المصطفى, الرياض")])])])]), _vm._v(" "), _c('div', {
    staticClass: "flex w-full px-5 py-6 mx-2 bg-white overscroll-auto shadow-gray-lg-40 md:w-1/2 item rounded-xl"
  }, [_c('img', {
    staticClass: "self-start me-5",
    attrs: {
      "src": require("assets/images/Home/Avatar.svg"),
      "alt": ""
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "flex flex-col"
  }, [_c('p', {
    staticClass: "mb-3 text-base font-light text-gray-400"
  }, [_vm._v("هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد\n                          النص العربى،\n                          حيث يمكنك أن تولد مثل هذا\n                          النص أو العديد من النصوص")]), _vm._v(" "), _c('div', {
    staticClass: "flex flex-row items-center"
  }, [_c('i', {
    staticClass: "text-blue-500 fa fa-user"
  }), _vm._v(" "), _c('h3', {
    staticClass: "text-base text-gray-600 ms-2"
  }, [_vm._v("يعرب المصطفى, الرياض")])])])])])])]);
}]

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-full my-10 mt-20 LastNews"
  }, [_c('section', {
    staticClass: "px-6 mx-auto mt-0 max-w-7xl all-places sm:px-10 lg:px-15"
  }, [_c('div', {
    staticClass: "flex flex-row justify-between all-places-header"
  }, [_c('div', {
    staticClass: "text-gray-700 align-start"
  }, [_vm._v("أخر المقالات")]), _vm._v(" "), _c('div', {
    staticClass: "flex items-center "
  }, [_c('a', {
    staticClass: "flex flex-row text-gray-700 ",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("\n          مشاهدة المزيد\n          "), _c('div', {
    staticClass: "ms-1"
  }, [_c('svg', {
    staticClass: "w-6 h-6 ",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor"
    }
  }, [_c('path', {
    attrs: {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "d": "M7 16l-4-4m0 0l4-4m-4 4h18"
    }
  })])])])])]), _vm._v(" "), _c('div', {
    staticClass: "flex flex-wrap items-center mx-auto mt-5 md:ps-7 justify-items-stretch"
  }, _vm._l(_vm.lastnews, function (news, index) {
    return _c('a', {
      key: 'news-' + index,
      staticClass: "flex flex-col w-full mb-5 ps-4 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 ",
      class: index != 0 && index % 3 == 0 ? 'pe-0' : 'pe-4',
      attrs: {
        "href": "javascript:void()"
      }
    }, [_c('div', {
      staticClass: "relative bg-white border border-gray-100 shadow-gray-lg-40 rounded-2xl"
    }, [_c('div', {
      staticClass: "overflow-hidden max-h-28 h-28"
    }, [_c('img', {
      staticClass: "z-30 object-cover w-full transition-all duration-300 h-28 hover:h-36 rounded-t-2xl",
      attrs: {
        "src": news.image,
        "alt": "photo here in all "
      }
    })]), _vm._v(" "), _c('div', {
      staticClass: "max-w-[16rem] mx-auto px-3 py-2"
    }, [_c('div', {
      staticClass: "mb-2 h-30 me-1"
    }, [_c('h4', {
      staticClass: "my-4 text-base text-gray-700 ms-2"
    }, [_vm._v(_vm._s(news.title))]), _vm._v(" "), _c('p', {
      staticClass: "text-sm text-gray-500 ms-2"
    }, [_vm._v(_vm._s(news.description))])])])])]);
  }), 0)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
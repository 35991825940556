//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { hexToRgb } from '~/plugins/helpers'
export default {
  name: 'Tag',
  props: {
    type: {
      type: String,
      default: 'default',
      required: true,
    },
    textColor: {
      type: String,
      default: null,
      required: false,
    },
  },
  computed: {
    colors() {
      const color =
        {
          defualt: 'blue',
          info: 'green',
          primary: 'primary',
          warning: 'yellow',
          danger: 'red',
        }[this.type ?? 'defualt'] ?? this.type
      if (color.startsWith('#')) {
        return `text-opacity-60 bg-opacity-10 hover:bg-opacity-20 hover:text-opacity-700`
      }
      return `text-${color}-600 bg-${color}-100 hover:bg-${color}-200 hover:text-${color}-700`
    },
    // eslint-disable-next-line vue/return-in-computed-property
    styles() {
      if (this.type && this.type.startsWith('#')) {
        const color = hexToRgb(this.type)
        if(this.textColor) {
          return `background-color:rgba(${color}, var(--tw-bg-opacity)); color: ${this.textColor};`
        }
        return `background-color:rgba(${color}, var(--tw-bg-opacity)); color:rgba(${color}, var(--tw-text-opacity))`
      }
    },
  },
}

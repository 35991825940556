// @vue/component
import FieldInput    from '~/components/Modals/FieldInput/FieldInput'
import PrimaryButton from '~/components/AppButton/PrimaryButton'
export default {
    name: 'LastNews',

    components: {
      FieldInput,
      PrimaryButton
    },

    props: {},

    data () {
        return {
          searchInput: null,
          lastnews: [
            {
              image: require('~/assets/images/Home/1.png'),
              title: 'أمور يجب أن تقوم بها في الأسبوع الأخير ماقبل زواجك',
              description: "مرّت شهور من التّخطيط، والآن بدأت الأيام تتقارب شيئاً فشيئاً للفرحة الكبرى، ومن المعروف أنّ الأسبوع الأخير ..."
            },
            {
              image: require('~/assets/images/Home/1.png'),
              title: 'أمور يجب أن تقوم بها في الأسبوع الأخير ماقبل زواجك',
              description: "مرّت شهور من التّخطيط، والآن بدأت الأيام تتقارب شيئاً فشيئاً للفرحة الكبرى، ومن المعروف أنّ الأسبوع الأخير ..."
            },
            {
              image: require('~/assets/images/Home/1.png'),
              title: 'أمور يجب أن تقوم بها في الأسبوع الأخير ماقبل زواجك',
              description: "مرّت شهور من التّخطيط، والآن بدأت الأيام تتقارب شيئاً فشيئاً للفرحة الكبرى، ومن المعروف أنّ الأسبوع الأخير ..."
            },
            {
              image: require('~/assets/images/Home/1.png'),
              title: 'أمور يجب أن تقوم بها في الأسبوع الأخير ماقبل زواجك',
              description: "مرّت شهور من التّخطيط، والآن بدأت الأيام تتقارب شيئاً فشيئاً للفرحة الكبرى، ومن المعروف أنّ الأسبوع الأخير ..."
            },
            {
              image: require('~/assets/images/Home/1.png'),
              title: 'أمور يجب أن تقوم بها في الأسبوع الأخير ماقبل زواجك',
              description: "مرّت شهور من التّخطيط، والآن بدأت الأيام تتقارب شيئاً فشيئاً للفرحة الكبرى، ومن المعروف أنّ الأسبوع الأخير ..."
            },
            {
              image: require('~/assets/images/Home/1.png'),
              title: 'أمور يجب أن تقوم بها في الأسبوع الأخير ماقبل زواجك',
              description: "مرّت شهور من التّخطيط، والآن بدأت الأيام تتقارب شيئاً فشيئاً للفرحة الكبرى، ومن المعروف أنّ الأسبوع الأخير ..."
            },
          ]
        }
    },

    computed: {},

    watch: {},

    created () {},

    methods: {
      updateSearchInput(value) {
        this.searchInput = value
      },
      search(){
        alert('search')
      }
    }
}

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('section', {
    staticClass: "relative w-full px-2 mx-auto max-w-7xl sm:px-6 lg:px-8 lg:flex heroSection"
  }, [_c('div', {
    staticClass: "flex flex-col justify-center flex-1 w-full px-5 pt-10 pb-20 max-w-7xl lg:py-2 lg:px-0 align-center"
  }, [_c('div', {
    staticClass: "max-w-full"
  }, [_c('h1', {
    staticClass: "text-4xl font-extrabold text-secondary text-center mt-24 mb-8"
  }, [_vm._v("\n          ...احجز مناسبتك كاملة، بضغطة زر مع تطبيق ايفنتفل\n        ")])])])])]);
}]

export { render, staticRenderFns }
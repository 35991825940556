//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FieldContainer from './FieldContainer/FieldContainer'

import PrimaryButton from '~/components/AppButton/PrimaryButton'
import { displayError } from '~/plugins/helpers'

export default {
  name: 'SearchForm',
  components: {
    PrimaryButton,
    FieldContainer,
  },
  data() {
    return {
      dateTitle: 'تاريخ المناسبة',
      guestTitle: 'عدد الضيوف',
      minDateInput: '2019-01-01',
      maxDateInput: '2025-01-01',
      dateInputFormat: {
        input: 'YYYY-MM-DD',
      },
      arrowIcon: 'arrow',
      calenderIcon: 'calender',
      guestIcon: 'guests',
      ParentInput: '',
    }
  },
  computed: {
    eventTypes() {
      return this.$store.state.searchBox.eventTypes ?? []
    },
    provinces() {
      return this.$store.state.searchBox.provinces ?? []
    },
    query() {
      return this.$store.state.searchBox.query ?? []
    },
    selectedEventType() {
      return this.eventTypes[this.filterEventInput] ?? this.eventTypes[0]
    },
    guestsInputForSelectedEventType() {
      let inputs = []
      if (!!this.selectedEventType?.guests_inputs) {
        this.selectedEventType?.guests_inputs.forEach((input) => {
          inputs.push(input)
        })
      }
      return inputs
    },
  },

  watch: {
    filterGuestsInputs: {
      deep: true, // to watch any change in the inner keys

      // We have to move our method to a handler field
      handler(value) {
        // console.log('\n\n\n\n\n\nchaaaanged', value);
      },
    },
  },
  async mounted() {
    // setup the minimum selectable date to be today
    this.minDateInput = this.$moment()
      .add(1, 'days')
      .locale('en')
      .format('YYYY-MM-DD')
    try {
      await this.$store.dispatch(
        'searchBox/getEventTypes',
        this.isPlaceApp ? this.$router.currentRoute.params.id ?? null : null
      )
      await this.$store.dispatch('searchBox/getProvinces')
    } catch (e) {
      console.log('Error: ', JSON.parse(JSON.stringify(e)))
      this.$swal('Error!', e.message, 'error')
    }
  },
  methods: {
    getURL(icon) {
      return require(`~/assets/images/searchForm/${icon}.svg`)
    },

    isEmpty(value) {
      return value === '' || value == null
    },

    async submitForm() {
      this.$emit('searchAgain')
      try {
        const data = {}
        await this.$store.dispatch('searchBox/getPlaces', data)
      } catch (e) {
        displayError(e, this)
      }
      await this.$store.dispatch('searchBox/getCategoriesByEventType')
    },
  },
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PrimaryButton from '~/components/AppButton/PrimaryButton.vue'

export default {
  components: {
    PrimaryButton,
  },
  methods: {
    async doSomething() {
      await this.$store.commit('businessEvents/SET_IS_EDIT_EVENT_REQUESTS', false)
      await this.$store.commit('businessEvents/SET_CURRENT_EVENT_REQUESTS', null)
      this.$router.push({
        path: this.localePath(`/businessEvents`),
      })
    },
  },
}

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "px-2 mx-auto max-w-5xl sm:px-6 lg:px-8 mt-14"
  }, [_c('div', {
    staticClass: "flex justify-between px-4 m-auto rounded-lg shadow-xl bg-white-700 hover:shadow-2xl cursor-pointer",
    on: {
      "click": _vm.showPlanners
    }
  }, [_vm._m(0), _vm._v(" "), _c('img', {
    staticClass: "rounded-lg w-48 h-28 lg:w-72 xl:w-72 md:w-72 xl:h-full lg:h-full md:h-full",
    attrs: {
      "src": require("assets/images/Planners/Home.png"),
      "alt": ""
    }
  })])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-col space-y-4"
  }, [_c('div', {
    staticClass: "text-purple-700 text-2xl"
  }, [_vm._v("جرب منسق ايفينتفل")]), _vm._v(" "), _c('div', {
    staticClass: "text-gray-400 text-md xl:text-xl lg:text-xl md:text-xl"
  }, [_vm._v("\n        طريقة مبتكرة لتنسيق مناسبتك كاملة بشكل سهل ومميز!\n      ")])]);
}]

export { render, staticRenderFns }
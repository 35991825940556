//
//
//
//
//
//
//

export default {
  props: ['sectionTitle']
}

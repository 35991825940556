//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex'
import NewsLetter from '~/components/HomeComponents/NewsLetter/NewsLetter'
import AllPlacess from '~/components/HomeComponents/AllPlacess/AllPlacess'
import HeroSection from '~/components/HomeComponents/HeroSection1/HeroSection'
import SearchFormContainer from '~/components/SearchFormContainer/SearchFormContainer'
import PrimaryButton from '~/components/AppButton/PrimaryButton'
import LastNews from '~/components/HomeComponents/LastNews/LastNews.vue'
import Services from '~/components/HomeComponents/Services/Services.vue'
import BusinessEvents from '~/components/HomeComponents/BusinessEvents/BusinessEvents.vue'
import Planner from '~/components/HomeComponents/Planners/Planner.vue'

export default {
  computed: {},
  components: {
    SearchFormContainer,
    HeroSection,
    PrimaryButton,
    NewsLetter,
    AllPlacess,
    LastNews,
    Services,
    BusinessEvents,
    Planner,
  },
  layout: 'default',

  methods: {
    ...mapMutations({
      toggleLogModal: 'login/toggleModal',
      toggleRegModal: 'regModal/toggleModal',
      toggleResetModal: 'resetModal/toggleModal',
      toggleRetrieveModal: 'retrieveModal/toggleModal',
      toggleActivateModal: 'activateModal/toggleModal',
    }),
    onFinsishTabbyPayment(tabby_payment_status) {
      this.$swal({
        text:
          tabby_payment_status == 'success'
            ? 'تم الدفع بنجاح'
            : tabby_payment_status == 'failed'
            ? 'حدث خطأ أثناء الدفع'
            : 'لقد قمت بإلغاء الدفع',
        icon:
          tabby_payment_status == 'success'
            ? 'success'
            : tabby_payment_status == 'failed'
            ? 'error'
            : 'info',
        dangerMode: true,
        allowOutsideClick: false,
        showConfirmButton: false,
        timer: 2000,
      })
    },
  },
  // async mounted() {
  //   await this.$store.dispatch('booking/getCheckoutResult')
  // },

  mounted() {
    if (
      localStorage.getItem('bookingId') &&
      this.$route.query.tabby_payment_status
    ) {
      this.onFinsishTabbyPayment(this.$route.query.tabby_payment_status)

      this.$router.push(
        `/dashboard/booking/${localStorage.getItem('bookingId')}`
      )
      localStorage.removeItem('bookingId')
    }
  },
  created() {
    if (typeof window !== 'undefined' && window.location.hash === '#login') {
      this.$swal('المعذرة', 'الرجاء تسجيل الدخول لإكمال العملية', 'error')
      this.toggleLogModal()
    }
  },
}

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "\n    px-4\n    py-0\n    text-sm\n    delay-75\n    rounded\n    outline-none\n    focus:outline-none\n    hover:outline-none\n  ",
    class: _vm.colors,
    style: _vm.styles
  }, [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-full my-10 mt-20 AllPlacess"
  }, [_c('section', {
    staticClass: "px-6 mx-auto mt-0 max-w-7xl all-places sm:px-10 lg:px-15"
  }, [_c('div', {
    staticClass: "flex flex-row justify-between all-places-header"
  }, [_c('div', {
    staticClass: "text-gray-700 align-start"
  }, [_vm._t("headerText", function () {
    return [_vm._v("أماكن مقترحة")];
  })], 2), _vm._v(" "), _vm.hasShowAllLink ? _c('div', {
    staticClass: "flex items-center"
  }, [_c('NuxtLink', {
    staticClass: "flex flex-row text-gray-700",
    attrs: {
      "to": _vm.localePath('/places')
    }
  }, [_vm._v("\n          مشاهدة المزيد\n          "), _c('div', {
    staticClass: "ms-1"
  }, [_c('svg', {
    staticClass: "w-6 h-6",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor"
    }
  }, [_c('path', {
    attrs: {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "d": "M7 16l-4-4m0 0l4-4m-4 4h18"
    }
  })])])])], 1) : _vm._e()]), _vm._v(" "), _vm._t('categories'), _vm._v(" "), _c('div', {
    staticClass: "flex flex-wrap items-center mx-auto mt-5 justify-items-stretch"
  }, _vm._l(_vm.places, function (place, index) {
    return _c('NuxtLink', {
      key: 'place-' + index,
      staticClass: "sm:pe-4 flex flex-col w-full my-5 sm:ps-4 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/3",
      attrs: {
        "to": {
          path: _vm.localePath('/places/details/' + place.id),
          query: {
            name: place.name
          }
        },
        "target": {
          _blank: _vm.open_external_link
        }
      }
    }, [_c('div', {
      staticClass: "relative flex bg-white border border-gray-100 shad ow-md shadow-gray-lg-40"
    }, [_c('div', {
      staticClass: "new-card overflow-hidden h-36 max-h-36"
    }, [_c('img', {
      staticClass: "main-img z-30 object-cover h-full transition-all duration-300 hover:h-50 rounded-r-2xl",
      attrs: {
        "src": place.main_image_for_api,
        "alt": "photo here in all "
      }
    }), _vm._v(" "), place.logo ? _c('img', {
      staticClass: "logo-img",
      attrs: {
        "src": place.logo_for_api
      },
      on: {
        "load": function ($event) {
          return _vm.loadLogo(index);
        }
      }
    }) : _vm._e()]), _vm._v(" "), _c('div', {
      staticClass: "px-3 py-2"
    }, [_c('div', {
      staticClass: "flex justify-end mt-0"
    }, [_c('div', {
      staticClass: "h-5 min-h-5"
    }, [place.outer_tag ? _c('Tag', {
      attrs: {
        "type": place.outer_tag.color || 'default'
      }
    }, [_vm._v(_vm._s(place.outer_tag.name.slice(0, 14) + (place.outer_tag.name.length > 14 ? '...' : '') || ''))]) : _vm._e()], 1)]), _vm._v(" "), _c('div', {
      staticClass: "flex items-center justify-between mt-2"
    }, [_c('div', {
      staticClass: "flex mb-1"
    }, [_c('img', {
      attrs: {
        "src": require("assets/images/Home/Icon-material-subtitles.svg"),
        "alt": ""
      }
    }), _vm._v(" "), _c('div', {
      staticClass: "text-black ms-2 name"
    }, [_vm._v("\n                  " + _vm._s(place.arabic_name) + "\n                ")])])]), _vm._v(" "), _c('div', {
      staticClass: "flex items-center"
    }, [_c('img', {
      attrs: {
        "src": require("assets/images/Home/money-bill-wave.svg"),
        "alt": ""
      }
    }), _vm._v(" "), _c('div', {
      staticClass: "text-gray-500 ms-2"
    }, [_vm._v("\n                " + _vm._s(place.text_price ? place.text_price : _vm.$t('home.not_detected')) + "\n              ")])]), _vm._v(" "), _c('div', {
      staticClass: "btn"
    }, [_c('button', [_vm._v("اختار")])])])])]);
  }), 1), _vm._v(" "), _vm.hasPageination ? _c('div', {
    staticClass: "flex mt-10"
  }, [_c('div', {
    staticClass: "mx-auto"
  }, _vm._l(_vm.totalPages, function (page) {
    return _c('button', {
      key: page,
      staticClass: "w-10 h-10 text-sm font-light leading-none text-center transition-colors bg-gray-300 rounded-full me-4 hover:outline-none focus:outline-none hover:bg-gray-400 focus:bg-primary-700 hover:text-white",
      class: [page == _vm.currentPage ? 'bg-primary-900 text-white' : ''],
      on: {
        "click": function ($event) {
          return _vm.setActivePage(page);
        }
      }
    }, [_vm._v("\n          " + _vm._s(page) + "\n        ")]);
  }), 0)]) : _vm._e()], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
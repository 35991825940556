var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "px-2 mx-auto max-w-7xl sm:px-6 lg:px-8 "
  }, [_c('div', {
    staticClass: "flex flex-wrap items-center justify-between my-12 services lg:flex-nowrap md"
  }, [_c('div', {
    staticClass: "flex items-center justify-center w-full max-w-full px-10 my-3 transition-all rounded-lg bg-gray-50 lg:mx-4 lg:w-1/3 hover:border-s border-s-transparent hover:border-s-primary hover:bg-white hover:shadow-gray-xl-40 flex-1/1 lg:flex-1/3 lg:my-0 lg:max-w-1/3 py-7"
  }, [_c('div', [_c('img', {
    attrs: {
      "src": "images/serv1.svg",
      "alt": ""
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "mr-4"
  }, [_c('h3', {
    staticClass: "text-lg font-medium mb-1.5"
  }, [_vm._v("\n                      خدمات متعددة\n                  ")]), _vm._v(" "), _c('p', {
    staticClass: "font-light"
  }, [_vm._v("\n                      صبابين ومطاعم وغيرها الكثير\n                  ")])])]), _vm._v(" "), _c('div', {
    staticClass: "flex items-center justify-center w-full max-w-full px-10 my-3 transition-all rounded-lg bg-gray-50 lg:mx-4 lg:w-1/3 hover:border-s border-s-transparent hover:border-s-primary hover:bg-white hover:shadow-gray-xl-40 flex-1/1 lg:flex-1/3 lg:my-0 lg:max-w-1/3 py-7"
  }, [_c('div', [_c('img', {
    attrs: {
      "src": "images/serv2.svg",
      "alt": ""
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "mr-4"
  }, [_c('h3', {
    staticClass: "text-lg font-medium mb-1.5"
  }, [_vm._v("\n                      دفع آمن وحجز سريع\n                  ")]), _vm._v(" "), _c('p', {
    staticClass: "font-light"
  }, [_vm._v("\n                      تستطيع الدفع والحجز بكل سهولة\n                  ")])])]), _vm._v(" "), _c('div', {
    staticClass: "flex items-center justify-center w-full max-w-full px-10 my-3 transition-all rounded-lg bg-gray-50 lg:mx-4 lg:w-1/3 hover:border-s border-s-transparent hover:border-s-primary hover:bg-white hover:shadow-gray-xl-40 flex-1/1 lg:flex-1/3 lg:my-0 lg:max-w-1/3 py-7"
  }, [_c('div', [_c('img', {
    attrs: {
      "src": "images/serv3.svg",
      "alt": ""
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "mr-4"
  }, [_c('h3', {
    staticClass: "text-lg font-medium mb-1.5"
  }, [_vm._v("\n                      العديد من الأماكن\n                  ")]), _vm._v(" "), _c('p', {
    staticClass: "font-light"
  }, [_vm._v("\n                      أكثر من 100 قاعة بالسعودية\n                  ")])])])])]);
}]

export { render, staticRenderFns }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FieldContainer',
  props: {
    // eslint-disable-next-line vue/require-prop-types
    source: {
      required: false,
    },
    isAbsoluteIcon: {
      default: false,
      type: Boolean,
    },
  },
}
